// Media
$width-xxsmall: 300px;
$width-xsmall: 420px;
$width-small: 720px;
$width-medium: 960px;
$width-large: 1200px;
$width-xlarge: 1440px;

// Menu
$menu-bar-height-mobile: 54px;
$menu-bar-height-desktop: 58px;

// AppStore Button
$appstore-button-height-mobile: 42px;
$appstore-button-height-desktop: 50px;

// Colours
$color-white: #ffffff;
$color-black: #000000;
$color-red: #E32832;
$color-orange: #fd591f;
$color-turquoise-bright: #3AF3F3;
$color-eastern-blue: #18b3a9;
$color-limed-spruce: #3c5353;
$color-cyan: #2dfbef;
$color-gray: #808080;
$color-outer-space: #272f2f;
$color-cape-cod: #373d3d;
$color-woodsmoke: #111717;
$color-yellow: #faff00;
$color-black-haze: #e6e8e8;
$color-hit-gray: #9CAAB1;
$color-shark: #1d2121;
$color-violet: #6E32E1;

$gradient-app-background-versi: linear-gradient(124deg, $color-cape-cod 12%, $color-woodsmoke 95%);

// Shapes
$border-radius-container-versi: 20px;

// UI
$ui-sizes: (
    "s": 0.8em,
    "m": 1.4em,
    "l": 2.4em //was 2em
);

$ui-radiuses: (
    "s": 0.2em,
    "m": 0.4em,
    "l": 0.8em //was 0.8em
);

$ui-font-sizes: (
    "s": 0.4em,
    "m": 0.7em,
    "l": 1em
);
