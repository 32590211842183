@import "~@kalkul/components/styles/vars";

.wrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    opacity: 0.2;

    svg {
        width: 3.4em;
        height: auto;
        margin-bottom: 0.5em;
    }

    div {
        color: $color-white;
        font-size: 0.4em;
        line-height: 1.2em;

        &.sub {
            font-size: 0.3em;
        }
    }
}
