@use "sass:math";

@import "../../styles/vars";
@import "../../styles/mixins";

@keyframes rotate {
    0% {
        transform: rotateZ(0deg);
    }
    100% {
        transform: rotateZ(360deg);
    }
}

$loader-sizes: (
    "s": 0.4em,
    "m": 0.6em,
    "l": 0.8em
);

@mixin loaderTransform($heightButton, $sizeLoader) {
    transform: translate3d(-50%, calc(50% + #{(math.div($heightButton, 2) - math.div($sizeLoader, 2))} * 2), 0);
}

.wrapper {
    position: relative;
    cursor: pointer;
    overflow: hidden;

    @each $size, $value in $ui-sizes {
        &[data-size="#{$size}"] {
            height: $value;
            border-radius: map-get($ui-radiuses, #{$size});

            &[data-pill="true"] {
                border-radius: math.div(map-get($ui-sizes, #{$size}), 2);
            }

            .content {
                font-size: map-get($ui-font-sizes, #{$size});
                padding: 0 map-get($ui-font-sizes, #{$size});
            }

            .loader {
                width: map-get($loader-sizes, #{$size});
                height: map-get($loader-sizes, #{$size});
                @include loaderTransform($value, map-get($loader-sizes, #{$size}));
            }
        }
    }

    &[data-busy="true"],
    &[data-disabled="true"] {
        pointer-events: none;
    }

    &[data-disabled="true"] {
        opacity: 0.4;
    }

    [data-theme="theme-light"] &,
    [data-theme="theme-system"] & {
        background-color: $color-black;
    }

    [data-theme="theme-dark"] &,
    [data-theme="theme-black"] & {
        background-color: $color-white;
    }

    &[data-cta="true"] {
        background: linear-gradient(84deg, #09ECDF 4%, #2FFDF1 95%);
        box-shadow: 2px 4px 8px 0 rgba($color-black, 0.1), 0px 0px 15px 0 rgba($color-eastern-blue, 0.36);

        .content {
            color: $color-black;
            text-decoration: none;
            font-weight: 700;

            & * {
                color: $color-black;
                text-decoration: none;
                font-weight: 700;
            }
        }
    }

    &[data-action="true"] {
        background-color: $color-yellow;

        .content {
            text-transform: uppercase;
            font-style: italic;
            font-weight: 700;
        }
    }

    &[data-theme="theme-danger"] {
        background-color: $color-red;
        .content {
            color: $color-white;
            font-weight: 700;
        }
    }

    a {
        text-decoration: none;
    }
}

.content {
    height: 100%;
    width: 100%;
    line-height: 235%;
    text-align: center;
    transform: translate3d(0, 0, 0);
    @include transition(opacity transform);

    [data-busy="true"] & {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }

    [data-theme="theme-light"] &,
    [data-theme="theme-system"] & {
        color: $color-white;
    }

    [data-theme="theme-dark"] &,
    [data-theme="theme-black"] & {
        color: $color-black;
    }
}

.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    @include transition(opacity transform);

    &:before {
        display: block;
        content: "";
        width: 100%;
        height: 100%;
        border-radius: 50%;
        @include animation(rotate, 1s);
    }

    [data-busy="true"] & {
        transform: translate3d(-50%, -50%, 0) !important;
    }

    [data-theme="theme-dark"] &,
    [data-theme="theme-black"] & {
        &:before {
            border: 4px solid rgba($color-black, 0.6);
            border-top-color: $color-black;
        }
    }

    [data-theme="theme-light"] &,
    [data-theme="theme-danger"] &,
    [data-theme="theme-system"] & {
        &:before {
            border: 4px solid rgba($color-white, 0.6);
            border-top-color: $color-white;
        }
    }
}
