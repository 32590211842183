@import "../../styles/vars";

.group {
    [data-theme="theme-light"] &,
    [data-theme="theme-system"] & {
        fill: $color-black;
    }

    [data-theme="theme-dark"] &,
    [data-theme="theme-black"] & {
        fill: $color-white;
    }
}
