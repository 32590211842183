@import "./vars";

// Media

@mixin media-min-width($width) {
    @if $width == xsmall {
        @media (min-width: #{$width-xsmall}) {
           @content;
        }
    } @else if $width == small {
        @media (min-width: #{$width-small}) {
           @content;
        }
    } @else if $width == medium {
        @media (min-width: #{$width-medium}) {
           @content;
        }
    } @else if $width == large {
        @media (min-width: #{$width-large}) {
           @content;
        }
    } @else if $width == xlarge {
        @media (min-width: #{$width-xlarge}) {
           @content;
        }
    }
}

@mixin media-landscape {
    @media (min-aspect-ratio: 1/1) {
        @content;
    }
}

// Image

@mixin image-reset {
    min-width: auto !important;
    max-width: auto !important;
    min-height: auto !important;
    max-height: auto !important;
    position: relative !important;
    top: auto !important;
    left: auto !important;
    bottom: auto !important;
    right: auto !important;
    width: auto !important;
    height: auto !important;
}

// Font

@mixin font-common {
    font-family: "System85 Pro", sans-serif;
    font-weight: 400;
    line-height: 1em;
}

@mixin font-mono {
    font-family: "System85 Mono Pro", sans-serif;
    font-weight: 400;
    line-height: 1em;
}

@mixin font-header {
    font-family: "System85 Pro", sans-serif;
    font-weight: 600;
    line-height: 1em;
    font-size: 1.5em;
    text-transform: uppercase;
}

// Popout Menu

@mixin popout-menu-item {
    height: 2em;
    line-height: 2em;
    font-size: 0.8em;
    font-style: italic;
    cursor: pointer;
    white-space: nowrap;
    text-transform: capitalize;
}

// Transition

@mixin transition($prop, $time: 150ms, $easing: ease-in-out, $delay: 0ms) {
    $transition: ();

    @for $i from 1 through length($prop) {
        @for $j from 0 to (length($prop)) - (length($time)) {
            $time: join($time, nth($time, -1));
        }

        @for $j from 0 to (length($prop)) - (length($easing)) {
            $easing: join($easing, nth($easing, -1));
        }

        @for $j from 0 to (length($prop)) - (length($delay)) {
            $delay: join($delay, nth($delay, -1));
        }

        $transition: append(
            $transition,
            (nth($prop, $i) nth($time, $i) nth($easing, $i) nth($delay, $i)),
            $separator: comma
        );
    }

    transition: $transition;
}

// Animation

@mixin animation($name, $duration, $timing-function: linear, $iteration-count: infinite, $delay: 0) {
    animation-name: $name;
    animation-duration: $duration;
    animation-timing-function: $timing-function;
    animation-iteration-count: $iteration-count;
    animation-delay: $delay;
}

// Containers

@mixin container-versi {
    border-radius: $border-radius-container-versi;
    box-shadow: 2px 4px 8px 0 rgba($color-black, 0.25);
    display: flex;
    flex-flow: column nowrap;
    overflow: hidden;

    [data-theme="theme-light"] &,
    [data-theme="theme-system"] & {
        background-color: $color-white;
    }

    [data-theme="theme-dark"] &,
    [data-theme="theme-black"] & {
        background-color: $color-outer-space;
    }
}

// Shadows

@mixin shape-drop-shadow-filter($style) {
    @if $style == default {
        filter: drop-shadow(0 3px 14px rgba($color-limed-spruce, 0.5));
    } @else if $style == light {
        filter: drop-shadow(0 3px 14px rgba($color-eastern-blue, 0.36));
    } @else if $style == dark {
        filter: drop-shadow(0 3px 14px rgba($color-eastern-blue, 0.36));
    }
}

// Aura Gradient
@mixin aura-gradient {
    background: linear-gradient(90deg,
        #1590F8 0%,
        #34CEFF 10%,
        #9386FF 20%,
        #FF6086 30%,
        #FC9895 40%,
        #1590F8 50%,
        #34CEFF 60%,
        #9386FF 70%,
        #FF6086 80%,
        #FC9895 90%,
        #1590F8 100%
    );
    background-size: 200% 100%;
}
