@import "~@kalkul/components/styles/vars";

.wrapper {
    background: $gradient-app-background-versi;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    flex-flow: column nowrap;
    width: 100%;

    form {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        width: 100%;
    }
}

.controls {
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    width: 100%;
    padding: 0.5em;
    margin-bottom: 1em;
    max-width: $width-xxsmall;

    input {
        text-align: center;
    }
}

.header {
    margin-bottom: 1em;
    white-space: nowrap;

    span {
        font-size: 2.6em;
        text-transform: capitalize;

        b {
            text-transform: uppercase;
        }
    }

    sup {
        font-size: 0.8em;
        position: relative;
        top: -1em;
    }
}

.intro {
    font-size: 0.6em;
    line-height: 1.4em;
    text-align: center;
    margin-bottom: 2em;
    min-width: 50vw;
}

.row {
    margin-top: 0.5em;
    width: 100%;

    input {
        display: block;
        width: 100%;
    }
}

.textCTA {
    font-size: 0.4em;
    cursor: pointer;
    text-decoration: underline;

    &[data-disabled="true"] {
        opacity: 20%;
        pointer-events: none;
        text-decoration: none;
    }
}

.forgotPassword {
    align-self: flex-end;
    margin-bottom: 0.5em;
}

.or {
    font-size: 0.6em;
    padding: 1em 0;
}

.error {
    background-color: $color-orange;
    border-radius: 20px;
    font-size: 0.6em;
    padding: 0.8em 1em;
    line-height: 1.3em;
    margin-bottom: 1.6em;
}

.button {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    & > div:first-child {
        width: 100%;
    }
}
