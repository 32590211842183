@import "../../styles/vars";

.wrapper {
    font-size: 0.4em;
    text-align: right;

    [data-theme="theme-light"] &,
    [data-theme="theme-system"] & {
        color: rgba($color-black, 0.6);
    }

    [data-theme="theme-dark"] &,
    [data-theme="theme-black"] & {
        color: rgba($color-white, 0.6);
    }
}
