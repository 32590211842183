@use "sass:math";

@import "../../styles/vars";
@import "../../styles/mixins";

@keyframes rotate {
    0% {
        transform: translate3d(0, -50%, 0) rotateZ(0deg);
    }
    100% {
        transform: translate3d(0, -50%, 0) rotateZ(360deg);
    }
}

.wrapper {
    position: relative;
    display: flex;
    flex-flow: column nowrap;

    @each $size, $value in $ui-sizes {
        &[data-size="#{$size}"] {
            height: $value;

            .input {
                padding: 0 map-get($ui-font-sizes, #{$size});
                font-size: map-get($ui-font-sizes, #{$size});
                border-radius: map-get($ui-radiuses, #{$size});
                line-height: $value;
            }

            .indicatorBusy {
                top: math.div($value, 2);
                right: math.div(map-get($ui-font-sizes, #{$size}), 2);

                &:after {
                    width: math.div($value, 2);
                    height: math.div($value, 2);
                }
            }

            .filePlaceholder {
                padding: 0 map-get($ui-font-sizes, #{$size});
                font-size: map-get($ui-font-sizes, #{$size});
            }
        }
    }
}

.input {
    background: transparent;
    position: relative;
    height: 100%;

    &[data-busy="true"] {
        pointer-events: none;
    }

    &[readonly] {
        pointer-events: none;
    }

    [data-theme="theme-light"] &,
    [data-theme="theme-system"] & {
        border: 1px solid $color-black;
        color: $color-black;

        &[readonly],
        &:disabled,
        &::placeholder {
            color: rgba($color-black, 0.4);
        }

        &[data-pristine="false"] {

        }

        & ~ .indicatorBusy {
            &:after {
                border-color: rgba($color-black, 0.6);
                border-top-color: $color-black;
            }
        }

        &:invalid {

        }
    }

    [data-theme="theme-dark"] &,
    [data-theme="theme-black"] & {
        border: 1px solid $color-white;
        color: $color-white;

        &[readonly],
        &:disabled,
        &::placeholder {
            color: rgba($color-white, 0.4);
        }

        &[data-pristine="false"] {
            border-color: $color-yellow;
        }

        & ~ .indicatorBusy {
            &:after {
                border-color: rgba($color-white, 0.6);
                border-top-color: $color-white;
            }
        }

        &:invalid {
            border-color: $color-red;
        }
    }

    &[type="file"] {
        cursor: pointer;
        color: transparent;

        &[readonly],
        &::placeholder {
            color: transparent;
        }

        &::file-selector-button {
            display: none;
        }
    }
}

.indicatorBusy {
    position: absolute;
    width: 0;
    height: 0;
    pointer-events: none;

    &:after {
        position: absolute;
        right: 0;
        display: block;
        content: "";
        border-width: 4px;
        border-style: solid;
        border-radius: 50%;
        @include animation(rotate, 1s);
    }
}

.filePlaceholder {
    color: $color-white;
    height: 100%;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: absolute;
    transform: translate3d(1px, 1px, 0);
    pointer-events: none;
    line-height: 200%;
}

.counter {
    font-size: 0.4em;
    text-align: right;

    [data-theme="theme-light"] &,
    [data-theme="theme-system"] & {
        color: rgba($color-black, 0.6);
    }

    [data-theme="theme-dark"] &,
    [data-theme="theme-black"] & {
        color: rgba($color-white, 0.6);
    }
}
